@use "../abstracts/mixins";

.jenkins-section {
  border-top: 2px solid var(--panel-border-color);
  padding: var(--section-padding) 0 0 0;
  max-width: 1800px;

  &:first-of-type {
    border-top: none;
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &:empty {
    display: none;
  }

  &--no-border {
    border-top: none;
    padding-top: 0;
  }

  &--bottom-padding {
    padding-bottom: var(--section-padding);
  }
}

.jenkins-section__title {
  margin: 0 0 var(--section-padding) 0;
  font-size: 1.1rem;
  font-weight: 600;
}

.jenkins-section__items {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--section-padding);

  @media screen and (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1300px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (min-width: 1800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.jenkins-section__item a {
  @include mixins.item;

  display: flex;
  text-decoration: none;
  margin: 0;

  &::before,
  &::after {
    inset: -0.65rem;
  }

  dl {
    margin: 0;
    padding: 0;
    min-height: 48px;
  }

  .jenkins-section__item__icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1rem 0 0;
    width: 3rem;
    height: 3rem;
    flex-shrink: 0;
    color: var(--text-color);

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 100%;
      pointer-events: none;
      background: var(--item-background--active);
    }

    img,
    svg {
      position: relative;
      width: 50% !important;
      height: 50% !important;
      color: currentColor;
    }

    &__badge {
      position: absolute;
      top: -4px;
      right: -4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      font-size: 0.7rem;
      min-height: 20px;
      min-width: 20px;
      padding: 0 0.4rem;
      box-shadow: 0 1px 1px rgba(black, 0.1);
      animation: animate-in-badge var(--elastic-transition) 0.1s both;

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        background: linear-gradient(white, black);
        border-radius: 100px;
        mix-blend-mode: overlay;
        opacity: 0.35;
      }
    }
  }

  dt {
    font-size: 0.925rem;
    font-weight: 600;
    margin: 0.1rem 0 0.2rem;
    color: var(--text-color);
  }

  dd {
    color: var(--text-color-secondary);
    font-weight: 500;
    line-height: 1.6;
    margin: 0 0.66rem 0 0;
    font-size: 0.925rem;
  }
}

@keyframes animate-in-badge {
  from {
    transform: scale(0);
  }
}
